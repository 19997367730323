import { SiMaterialdesign } from "react-icons/si";
import { FiCode } from "react-icons/fi";

const services = [
    {
        title: "Web Design",
        description: "We can design your dream website that will be appealing as well as easy to use.",
        icon: <SiMaterialdesign/>
    },
    {
        title: "Web Development",
        description: "We can design your dream website that will be appealing as well as easy to use.",
        icon: <FiCode/>
    }
];


export default services;