import React from 'react'
import { BsClockHistory } from "react-icons/bs";

function Gigs() {

  const $colors = [
    "bg-green-300",
    "bg-yellow-300",
    "bg-blue-300",
    "bg-pink-300",
    "bg-sky-300",
    "bg-emerald-300",
    "bg-red-300",
    "bg-teal-300",
    "bg-cyan-300",
    "bg-indigo-300",
    "bg-violet-300",
    "bg-purple-300",
    "bg-fuchsia-300",
    "bg-orange-300",
    "bg-lime-300"
  ];

  return (
    <main className='relative bg-white z-50 py-24'>
      <div className='conainer px-5 mx-auto'>

        <div className="flex flex-col items-center text-gray-700 mb-7">
          <h1 className="text-5xl font-semibold mb-3">Gigs</h1>
          <h4 className="text-lg">Gigs that represents our services</h4>
        </div>

        <div className='p-5 flex flex-wrap justify-center items-center'>
          {[...Array(30).keys()].map((gig, index) => (
            <div key={index}
              className={'md:w-1/4 lg:w-1/5 m-3 cursor-pointer border border-black ' + $colors[Math.floor((index + 1) % 15)]}
              style={{ boxShadow: "0.25rem 0.25rem #000", transform: "translate(-0.25rem,-0.25rem)", borderRadius: "0.25rem" }}
            >
              <div className='px-3 py-3'>
                Lorem, ipsum dolor.
              </div>
              <p className='px-3 pb-5'>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam, hic?
              </p>
              <div className='bg-white rounded-bl rounded-br px-3 py-3 flex justify-between items-center'>
                <span className='inline-flex items-center'>
                  <BsClockHistory />
                  <span className='pl-1'>
                    Duration
                  </span>
                </span>
                <span className='inline-flex justify-between items-center bg-black text-white px-4 py-1 rounded-full'>
                  $
                  <span className='pl-2'>
                    100
                  </span>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>

  )
}

export default Gigs