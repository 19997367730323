import React from 'react'
import logo from "./../assets/images/telixar.svg"
import { FiGlobe } from "react-icons/fi";
import { BsFillTelephoneFill, BsStack, BsFillInfoCircleFill, BsFillChatTextFill, BsEnvelopeFill,BsWhatsapp } from "react-icons/bs";
import { RiServiceFill } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from 'react';
import { Link } from 'react-router-dom';


function Header() {

    const [menuVisibility, setMenuVisibility] = useState(false);

    return (
        <>
            <div className={(menuVisibility ? "" : "hidden")}>
                <menu className='p-3 bg-white fixed top-0 left-0 w-full h-full z-10' >
                    <button className='text-5xl' onClick={() => setMenuVisibility(false)}>
                        <AiOutlineClose />
                    </button>

                    <nav>
                        <Link onClick={() => setMenuVisibility(false)} to="our-services" className="flex items-center font-semibold text-3xl my-5 border-2 border-black p-2">
                            <RiServiceFill />
                            <span className="ml-2">
                                Services
                            </span>
                        </Link>
                        <Link onClick={() => setMenuVisibility(false)} to="our-products" className="flex items-center font-semibold text-3xl my-5 border-2 border-black p-2">
                            <BsStack />
                            <span className="ml-2">
                                Products
                            </span>
                        </Link>
                        <Link onClick={() => setMenuVisibility(false)} to="about-us" className="flex items-center font-semibold text-3xl my-5 border-2 border-black p-2">
                            <BsFillInfoCircleFill />
                            <span className="ml-2">
                                About Us
                            </span>
                        </Link>
                        <Link onClick={() => setMenuVisibility(false)} to="contact-us" className="flex items-center font-semibold text-3xl my-5 border-2 border-black p-2">
                            <BsFillChatTextFill />
                            <span className="ml-2">
                                Contact Us
                            </span>
                        </Link>
                    </nav>
                </menu>
            </div>

            <header className='border-t-2 border-black'>
                <div className='border-b-2 border-black'>
                    <div className='h-[50px] lg:mx-5 flex items-center font-semibold'>
                        <span className='px-5 h-full inline-flex items-center border-r-2 border-black'>
                            <BsWhatsapp />
                            <a className='pl-2' href="https://wa.me/01747599644">
                                <span className='hidden lg:inline font-sans'>
                                    +88 01747599644
                                </span>
                                <span className='lg:hidden'>
                                    Whatsapp Us
                                </span>
                            </a>
                        </span>

                        <span className='px-5 h-full inline-flex items-center border-r-2 border-black'>
                            <BsEnvelopeFill />
                            <a className='pl-2' href="mailto:telixar.com@gmail.com">
                                <span className='hidden lg:inline'>
                                    ask@telixar.com
                                </span>
                                <span className='lg:hidden'>
                                    Mail Us
                                </span>
                            </a>
                        </span>
                    </div>
                </div>

                <div className='h-[60px] border-b-2 border-black'>
                    <div className="lg:ml-5 flex justify-between lg:justify-start items-center">

                        {/* Hamburger Button */}
                        <div className='lg:hidden mx-3 flex justify-center items-center' onClick={() => setMenuVisibility(true)}>
                            <span className=''>
                                <GiHamburgerMenu className='text-3xl' />
                            </span>
                        </div>

                        {/* Branding */}
                        <Link to={"/"} className="lg:w-1/12 flex items-center">
                            <img className='ml-3' src={logo} alt="" style={{ height: "35px" }} />
                        </Link>

                        {/* Navigation */}
                        <nav className="h-[60px] lg:w-10/12 hidden lg:flex flex-wrap items-center text-base justify-center">
                            <Link to="our-services" className="relative h-full inline-flex items-center justify-center border-l-2 border-black px-5 hover:bg-black hover:text-white cursor-pointer font-semibold text-xl">
                                <RiServiceFill />
                                <span className="ml-2">
                                    Services
                                </span>
                            </Link>
                            <Link to="our-products" className="h-full inline-flex items-center justify-center border-l-2 border-black px-5 hover:bg-black hover:text-white cursor-pointer font-semibold text-xl">
                                <BsStack />
                                <span className="ml-2">
                                    Products
                                </span>
                            </Link>
                            <Link to="about-us" className="h-full inline-flex items-center justify-center border-l-2 border-black px-5 hover:bg-black hover:text-white cursor-pointer font-semibold text-xl">
                                <BsFillInfoCircleFill />
                                <span className="ml-2">
                                    About Us
                                </span>
                            </Link>
                            <Link to="contact-us" className="h-full inline-flex items-center justify-center border-l-2 border-r-2 border-black px-5 hover:bg-black hover:text-white cursor-pointer font-semibold text-xl">
                                <BsFillChatTextFill />
                                <span className="ml-2">
                                    Contact Us
                                </span>
                            </Link>
                        </nav>

                        {/* Explore Button */}
                        <div className='lg:w-1/12 h-[60px] flex items-center justify-end bg-black'>
                            <Link to={"gigs"} className="h-full  px-7 inline-flex items-center border border-black focus:outline-none text-white bg-black text-xl">
                                <FiGlobe />
                                <span className='mx-3 font-semibold'>
                                    Gigs
                                </span>
                            </Link>
                        </div>

                    </div>
                </div>
            </header>
        </>

    )
}

export default Header