import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ROUTES from '../routes'

function Body() {
    return (
        <>
            <Routes>
                {ROUTES.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element}/>
                ))}
            </Routes>
        </>
    )
}

export default Body