import React from 'react'
import stats from '../../data/stats'

function Stats() {
    return (
        <section className='stats-section py-24'>
            <div className='container px-5 mx-auto'>
                <div className='text-center mb-7'>
                    <h1 className='font-serf mb-3 font-semibold text-5xl'>Numbers Prove Us Also</h1>
                    <h4 className='text-2xl'>Numbers are not everything but they are not also nothing</h4>
                </div>
                <div className='flex flex-wrap lg:border border-black my-7'>
                    {stats.map((stat, index) => (
                        <div key={index} className='w-full lg:w-1/4 my-2 lg:my-0 h-[10em] border border-black flex flex-col items-center justify-center p-5'>
                            <span className='text-5xl font-bold'>
                                {stat.count}+
                            </span>
                            <span className='text-3xl font-semibold'>
                                {stat.type}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Stats