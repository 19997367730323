import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Pagination, Navigation } from "swiper";
function Reviews() {
    return (
        <section className="py-24 reviews-section">
            <div className="flex flex-col items-center text-gray-700 mb-7">
                <h1 className="text-5xl font-semibold mb-3">Clients' Reviews</h1>
                <h4 className="text-lg">Learn about our clients' feeling</h4>
            </div>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                slidesPerGroup={3}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {[...Array(5).keys()].map((elm, index) => (
                    <SwiperSlide className='border w-full md:w-1/2 lg:w-1/3 m-2 mb-10 p-5 rounded bg-white'>
                        <h3 className='text-2xl font-semibold'>Saimon Pierre</h3>
                        <h5 className="text-sm">Director X Corporation</h5>
                        <span className='text-yellow-500 text-lg font-bold'>*****</span>
                        <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur modi in quo totam amet minus?Lorem ipsum dolor sit amet
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, dolores!
                        </p>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>

    );
}


export default Reviews;
