import React from 'react'

function Products() {
  return (
    <main className='relative z-50 bg-white py-24'>
      <div className='container px-5 mx-auto'>

        <div className='flex flex-col justify-center items-center mb-7'>
          <h1 className='text-5xl font-bold mb-3'>Our Products</h1>
          <h4 className='text-xl'>Industry dominating and powerful products by telixar</h4>
        </div>

        <div className='flex flex-wrap flex-'>
          {[...Array(5).keys()].map((product, index) => (
            <div className='m-3 w-full md:w-1/4 border-2 border-black' style={{ boxShadow: "0.30rem 0.30rem #000", transform: "translate(-0.25rem,-0.25rem)", borderRadius: "0.25rem" }}>
              {/* header */}
              <div className='p-3'>
                <h2 className='text-2xl font-semibold'>This is a product</h2>
              </div>

              {/* body */}
              <div className='p-3'><img src="https://dummyimage.com/700X600" alt="" />
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem, sit.
              </div>

              {/* footer */}
              <div className='p-3'>
                <button className='bg-black text-gray-100 rounded-full text-xl font-semibold px-5 py-2'>
                  Details
                </button>
              </div>
            </div>
          ))}
        </div>



      </div>
    </main>
  )
}

export default Products