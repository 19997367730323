import React from 'react'
import { useState } from 'react';
import { BsPlusCircle, BsDashCircle, BsFillTelephoneFill, BsEnvelopeFill } from "react-icons/bs";

function FAQS() {

    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <section className='container mx-auto my-24 px-5'>
            <div className=''>
                <div className='flex flex-col items-center justify-center mb-7 text-center'>
                    <span className='font-sans font-bold text-lg mb-3'>
                        FAQs
                    </span>
                    <span className='font-serf font-semibold text-5xl mb-3'>
                        Frequently Asked Questions
                    </span>
                    <span className='text-gray-400 font-semibold text-2xl'>
                        Have Questions? We are here to help.
                    </span>
                </div>

                <div className='flex flex-col items-center w-full mb-5'>
                    {[...Array(5).keys()].map((elm, index) => (
                        <div key={index} className='border-b pb-5 my-3 w-full max-w-[1000px]'>
                            <div className='font-bold text-xl mb-3 flex justify-between'>
                                <span>
                                    This is the Qestion?
                                </span>
                                <BsPlusCircle className={"cursor-pointer " + ((activeIndex !== index) ? "" : "hidden")} onClick={() => setActiveIndex(index)} />
                                <BsDashCircle className={"cursor-pointer " + ((activeIndex !== index) ? "hidden" : "")} onClick={() => setActiveIndex(null)} />
                            </div>
                            <div className={'text-justify ' + ((activeIndex !== index) ? "hidden" : "")}>
                                This is the answer. Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus incidunt illum, neque consequuntur delectus sint ipsum mollitia officia. Quis unde cupiditate modi voluptate nulla iusto a. Voluptatibus officiis voluptatum eaque facilis, iure ut laborum laudantium beatae provident modi, rerum
                                soluta dolor distinctio natus exercitationem unde culpa sunt? Possimus, numquam rerum.
                            </div>
                        </div>
                    ))}
                </div>


                <div className='flex flex-col items-center justify-center mb-7 text-center '>
                    <span className='font-serf font-semibold text-3xl mb-3'>
                        Still have questions?
                    </span>
                    <span className='text-gray-400 font-semibold text-lg mb-3'>
                        Can't find the answer you are looking for? Call or mail us.
                    </span>
                    <div className='flex'>
                        <span className='inline-flex font-semibold items-center bg-emerald-600 text-white px-7 py-3 mx-2'>
                            <BsFillTelephoneFill />
                            <a className='pl-2' href="tel:+8801747599644">
                                Call Us
                            </a>
                        </span>

                        <span className='inline-flex font-semibold items-center bg-sky-600 text-white px-7 py-3 mx-2'>
                            <BsEnvelopeFill />
                            <a className='pl-2' href="mailto:telixar.com@gmail.com">
                                Mail Us
                            </a>
                        </span>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default FAQS