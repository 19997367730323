import React from 'react'
import ContactUsForm from '../components/forms/ContactUs'

function ContactUs() {
  return (
    <main>
      <ContactUsForm />
    </main>
  )
}

export default ContactUs