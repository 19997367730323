import React from 'react'
import Hero from "./../components/sections/Hero";
import Services from "./../components/sections/Services";
import FAQS from '../components/sections/FAQS';
import TechStack from '../components/sections/TechStack';
import Stats from '../components/sections/Stats';
import HashSpace from '../components/sections/HashSpace';
import Reviews from '../components/sections/Reviews';

function Home() {
    return (
        <>
            <Hero />
            <HashSpace />
            <Stats />
            <Reviews/>
            <TechStack />
            <Services />
            <FAQS />
        </>
    )
}

export default Home