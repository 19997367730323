import AboutUs from "./pages/AboutUs";
import HomePage from "./pages/Home";
import Services from "./pages/Services";
import Products from "./pages/Products";
import Gigs from "./pages/Gigs";
import ContactUs from "./pages/ContactUs";

const ROUTES = [
    {
        name: "home",
        path: "/",
        element: <HomePage />
    },
    {
        name: "about",
        path: "about-us",
        element: <AboutUs />
    },
    {
        name: "contact",
        path: "contact-us",
        element: <ContactUs />
    },
    {
        name: "services",
        path: "our-services",
        element: <Services />
    },
    {
        name: "products",
        path: "our-products",
        element: <Products />
    },
    {
        name: "gigs",
        path: "gigs",
        element: <Gigs />
    }
];

export default ROUTES;