import React from 'react'
import Reviews from '../components/sections/Reviews'

function AboutUs() {
  return (
    <main className='relative z-50 py-24 bg-white'>
      <Reviews />
    </main>
  )
}

export default AboutUs