import React from 'react'
import ServiceList from "./../data/services";
import { RiServiceLine } from "react-icons/ri";

function Services() {

    const colors = ["#116466", "#D79922", "#44318D", "#950740", "#F13C20", "#4056A1",];

    return (
        <section className="relative z-50 body-font py-24 services-section">
            <div className="container px-5  mx-auto">
                <div className="flex flex-col items-center text-gray-700 mb-7">
                    <h1 className="text-5xl font-semibold mb-3">Our Services</h1>
                    <h4 className="text-lg">Excellent Services Provided by Telixar</h4>
                </div>
                <div className="flex flex-wrap -m-4">
                    {ServiceList.map((service, index) => (
                        <div key={index} className="md:w-1/2 p-4 ">
                            <div className="border border-black p-6 rounded-lg text-white" style={{ backgroundColor: colors[index] }}>
                                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full mb-4">
                                    <span className='text-5xl'>
                                        {service.icon}
                                    </span>
                                </div>
                                <h2 className="text-3xl font-medium title-font mb-2">
                                    {service.title}
                                </h2>
                                <p className="leading-relaxed text-base">
                                    {service.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    )
}

export default Services