import React from 'react'
import Laravel from './../../assets/images/techstack/laravel.svg';
import php from "./../../assets/images/techstack/php.svg";
import react from "./../../assets/images/techstack/react.svg";
import javascript from "./../../assets/images/techstack/javascript.svg";
import sass from "./../../assets/images/techstack/sass.svg";
import tailwindcss from "./../../assets/images/techstack/tailwindcss.svg";
import any from "./../../assets/images/techstack/any.svg";

function TechStack() {
    return (
        <section className='my-24'>
            <div className='container px-5 mx-auto'>
                <div className='text-center mb-10'>
                    <h1 className='font-serf font-semibold text-5xl mb-3'>Technology Stack</h1>
                    <h4 className='text-gray-400 font-semibold text-2xl'>We use future-proof as well as robust technologies.</h4>
                </div>

                <div className='flex flex-col md:flex-row flex-wrap justify-around'>
                    <div className='m-3 flex justify-center'>
                        <img style={{ height: "100px" }} src={php} alt="" />
                    </div>
                    <div className='m-3 flex justify-center'>
                        <img style={{ height: "100px" }} src={Laravel} alt="" />
                    </div>
                    <div className='m-3 flex justify-center'>
                        <img style={{ height: "100px" }} src={javascript} alt="" />
                    </div>
                    <div className='m-3 flex justify-center'>
                        <img style={{ height: "100px" }} src={react} alt="" />
                    </div>
                    <div className='m-3 flex justify-center'>
                        <img style={{ height: "100px" }} src={sass} alt="" />
                    </div>
                    <div className='m-3 flex justify-center'>
                        <img style={{ height: "100px" }} src={tailwindcss} alt="" />
                    </div>
                    <div className='m-3 flex justify-center'>
                        <img style={{ height: "100px" }} src={any} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TechStack