import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Body from './components/Body';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header></Header>
        <Body></Body>
        <Footer></Footer>
      </div>
    </BrowserRouter>

  );
}

export default App;
