import React from 'react'
import { Link } from 'react-router-dom';

import SuccessIMG from "./../../assets/images/success.png";

function Hero() {
    return (
        <section className="text-gray-600 body-font bg-black">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="sm:text-4xl text-3xl mb-4 font-medium text-gray-100">
                        Join Hundreds of
                        <br className="hidden lg:inline-block" />
                        Success travellers
                    </h1>
                    <p className="mb-8 leading-relaxed text-gray-100 text-lg">
                        Becoming digitally successfull is a tough journey, we are ready to accompany you in this journey.
                        Our excellent services and products will be the great companion for your journey.
                        Let's Start!
                    </p>
                    <div className="flex justify-center">
                        <Link to={"our-services"} className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg hover:shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)]">
                            Service
                        </Link>
                        <Link to={"our-products"} className="ml-4 inline-flex bg-emerald-500 text-white border-0 py-2 px-6 focus:outline-none hover:bg-emerald-600 rounded text-lg hover:shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)]">
                            Products
                        </Link>
                    </div>
                </div>
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <img
                        className="object-cover object-center rounded"
                        alt="hero"
                        src={SuccessIMG}
                    />
                </div>
            </div>
        </section>

    )
}

export default Hero