import React from 'react'
import HashIMG from "./../../assets/images/hash.png"

function HashSpace() {

    const hashList = [
        "Web Devlopment",
        "Web Design",
        "Website Creating",
        "Website Maintainance",
        "Website Refactoring",
        "Website Builder",
        "Custom Software",
        "Management System",
        "Mobile Apps",
        "App Development",
        "Custom System"
    ];

    return (
        <section className='py-24 hashspace'>
            <div className='container px-5 mx-auto'>
                <div className='text-center mb-7'>
                    <h1 className='font-serif text-gray-900 mb-3 font-semibold text-5xl'>HashSpace</h1>
                    <h4 className='text-gray-900 text-2xl'>Hashes that define us</h4>
                </div>
                <div className='rounded flex flex-wrap p-5'>
                    {hashList.map((hash, index) => (
                        <span key={index}
                         className='border-2 border-black text-black cursor-pointer hover:bg-black hover:text-white rounded m-2 px-5 py-3 font-semibold text-xl shadow-[0_8px_30px_rgb(0,0,0,0.12)]'>
                            # {hash}
                        </span>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default HashSpace