const stats = [
    {
        count: 100,
        type: "Projects"
    },
    {
        count: 100,
        type: "Projects"
    },
    {
        count: 100,
        type: "Projects"
    },
    {
        count: 100,
        type: "Projects"
    },
    {
        count: 100,
        type: "Projects"
    },
    {
        count: 100,
        type: "Projects"
    },
    {
        count: 100,
        type: "Projects"
    },
    {
        count: 100,
        type: "Projects"
    }
]

export default stats;