import React from 'react'
import logoInverted from "./../assets/images/telixar-inverted.svg"
import { BsArrowRight, BsFacebook, BsLinkedin, BsTwitter, BsInstagram, BsYoutube,BsWhatsapp } from "react-icons/bs";
import { FaSitemap } from "react-icons/fa";

function Footer() {
    return (
        <footer className="text-white bg-black body-font pt-24">
            <div className="container mx-auto">
                <div className='flex flex-col lg:flex-row items-between justify-between'>

                    <div className='flex flex-col items-center md:items-start mb-10 md:mb-0'>
                        <a className="">
                            <img src={logoInverted} alt="" style={{ height: "50px" }} />
                        </a>
                        <div className='flex text-2xl mt-3'>
                            <BsWhatsapp className="mr-5"/>
                            <BsFacebook className='mr-5' />
                            <BsTwitter className='mr-5' />
                            <BsLinkedin className='mr-5' />
                            <BsInstagram className='mr-5' />
                            <BsYoutube className='' />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className='flex flex-wrap -mb-10 -mx-4'>

                            {["Services", "Products", "Industries", "Solutions", "Meta", "Contributions", "Company"].map((menu, index) => (
                                <div key={index}
                                    className="flex py-3 justify-center lg:justify-start
                                            items-baseline lg:w-1/4 md:w-1/2 w-full px-4"
                                >
                                    <BsArrowRight />
                                    <div className='ml-2'>
                                        <h2 className="inline-flex uppercase text-sm items-center font-bold text-white tracking-widest mb-3">
                                            {menu}
                                        </h2>
                                        <nav className="list-none mb-10">
                                            <li>
                                                <a className="text-gray-100 hover:text-gray-800 font-medium">First Link</a>
                                            </li>
                                            <li>
                                                <a className="text-gray-100 hover:text-gray-800 font-medium">Second Link</a>
                                            </li>
                                            <li>
                                                <a className="text-gray-100 hover:text-gray-800 font-medium">Third Link</a>
                                            </li>
                                            <li>
                                                <a className="text-gray-100 hover:text-gray-800 font-medium">Fourth Link</a>
                                            </li>
                                        </nav>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>

                <div className='pt-5'>
                    <div className="border-t border-b border-white">

                        <div className="text-gray-100 py-2 px-5 flex flex-wrap flex-col sm:flex-row items-center sm:justify-between">
                            <p className="text-sm text-center sm:text-left mb-1 sm:mb-0">
                                © {(new Date()).getFullYear()} Telixar<sup>&#174;</sup> —
                                <a
                                    href=""
                                    className="ml-1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    All rights reserved
                                </a>
                            </p>
                            <span className="text-center sm:text-left mb-1 sm:mb-0 text-gray-100 text-sm">
                                <a className="flex title-font font-medium items-center">
                                    <span className='inline-flex items-center'>
                                        <FaSitemap />
                                        <span className="ml-2">
                                            Sitemap
                                        </span>
                                    </span>
                                </a>
                            </span>
                        </div>

                    </div>
                </div>


                <div className='flex flex-col md:flex-row items-center md:justify-between px-5 sm:px-0'>
                    <div className=''>

                    </div>
                    <div className='text-sm text-gray-400 flex justify-between items-center py-7'>
                        /<a href="" className='mx-2 border-white inline-flex justify-center items-center'>Privacy Policy</a>
                        /<a href="" className='mx-2 border-white inline-flex justify-center items-center'>Terms and Conditions</a>
                        /<a href="" className='mx-2 border-white inline-flex justify-center items-center'>Cookie Preference</a>/
                    </div>
                </div>

            </div>

        </footer>

    )
}

export default Footer